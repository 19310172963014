<template>
  <div class="login_background">
    <div class="login_main">
      <div class="form">
        <el-input prefix-icon="el-icon-user-solid" size="mini" clearable type="text" v-model="account" auto-complete="off"></el-input>
        <el-input style="margin-top:40px" prefix-icon="el-icon-lock" size="mini" clearable type="password" show-password v-model="password" auto-complete="off" minlength="6" maxlength="20"></el-input>
        <el-button style="margin-top: 40px;width:100%" @click="login" size="mini">登录</el-button>
      </div>
      
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data(){
    return{
      account:'',
      password:'',
      user:[]
    }
  },
  created(){
    this.fetchUser();
  },
  mounted(){
    
  },
  methods:{
    //登录
    login(){
      let account = this.account;
      let password = this.password;
      if(!account) return this.$message.error('请输入账号');
      if(!password) return this.$message.error('请输入密码');
      let flag = true;
      let data = {};
      this.user.map(item=>{
        // if(item.code == account){
        //   flag = true;
        //   if(item.password == password){
        //     flag = true;
        //   }else{
        //     flag = false;
        //   }
        // }else{
        //   flag = false
        // }
        if(item.code == account){
          data = item;
        }
      })
      // console.log(data,111)
      if(!data.code) return this.$message.error('请输入正确的账号');
      if(data.password != password) return this.$message.error('请输入正确的密码');
      sessionStorage.user = JSON.stringify(data);
      this.$router.push({ path: "/" });
    },
    async fetchUser() {
        try {
          // const response = await axios.get('https://cjf-nbjc.oss-cn-hangzhou.aliyuncs.com/config/userConfig.conf');
          const response = await axios.get('/config/userConfig.conf');
          this.user = response.data;
          // sessionStorage.user = JSON.stringify(this.user)
        } catch (error) {
          console.error('1111', error);
        }
      }
  }
}
</script>

<style lang="less" scoped>

    .login_background {
      width: 100%;
      display: flex;
      height: 100vh;
      align-items: center;
      overflow: hidden;
      background-image: url('/img/login-bg.png'); /* 图片背景 */
      background-size: 100% 100%;
      justify-content: flex-end;
      .login_main {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        justify-content: space-between;
        text-align: center;
        position: relative;
        z-index: 10;
        margin-right: 6%;
        margin-top: 50px;
        background-image: url('/img/login-box.png'); /* 图片背景 */
        background-size: 100% 100%;
        padding:30px;
        width: 380px;
        height: 380px;
        .form{
          margin-top: 60px;
          /deep/.el-input__prefix{
            left:20px !important;
          }
          /deep/.el-input__inner  {
            background-image: url('/img/login-input-bg.png'); /* 图片背景 */
            background-size: 100% 100%;
            padding-left: 50px !important;
            // background-size: cover;
            background-position: 100% 100%;
            background-color: transparent;
            // background-position: center; /* 背景图片居中 */
            border:none;
            color:#fff;
          }
          /deep/.el-input__prefix, .el-input__suffix{
            color:#fff;
          }
          /deep/button  {
            background-image: url('/img/buttonbg.png'); /* 图片背景 */
            background-size: 100% 100%;
            // background-size: cover;
            background-position: 100% 100%;
            background-color: transparent;
            // background-position: center; /* 背景图片居中 */
            border:none;
            color:#fff;
          }
          /deep/button:hover  {
            background-image: url('/img/buttonbg_hover.png'); /* 图片背景 */
            
          }
        }
        
      }
    }
</style>